import { useEffect, useState } from 'react'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { convertToUTCByMSK, formatSum } from '../../../../utils'
import { getDefaultDateRange } from '../../../../utils/getDefaultDateRange'
import { STATUS_COLOR } from '../../../../constants'
import { ReportBlock } from '../../payment-tickets/components/ReportBlock'
import ReportTicketsFilter from '../../payment-tickets/components/ReportTicketsFilter'
import { ReportBlockItems } from '../../payment-tickets/core/_models'
import { reportTicketsAPI } from '../../payment-tickets/core/_requests'
import { ROLE, useAuth } from '../../auth'

const orderStatuses = ['ACTIVE', 'IN_PROGRESS', 'COMPLETED', 'DECLINED']

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payout-tickets/active',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payout-tickets/finished',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Отчёт',
    path: '/payout-tickets/report',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const defaultBlocks: ReportBlockItems = {
  [new Date().getTime()]: [],
}

const ReportTickets = () => {
  const { currentUser } = useAuth()
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<any>({
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
    ticketType: 'payout',
    merchantTokenList: [],
    cashBoxIdList: [],
    paymentAdapterIdList: [],
    withoutPaymentMethod: false,
  })
  const [blocks, setBlocks] = useState(defaultBlocks)

  const updateReportBlocks = async (reset?: boolean) => {
    const {
      dateStart,
      dateEnd,
      cashBoxId,
      merchantToken,
      paymentMethod,
      ticketProcessType,
      paymentAdapterIdList,
      ...rest
    } = filters
    let query: any = {}

    if (reset) {
      query = {
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
        ticketType: rest.ticketType,
        cashBoxIdList: [],
        merchantTokenList: [],
        paymentAdapterIdList: [],
        withoutPaymentMethod: false,
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...rest,
        dateStart: convertToUTCByMSK(dateStart),
        dateEnd: convertToUTCByMSK(dateEnd),
        cashBoxId: cashBoxId?.[0],
        merchantToken: merchantToken,
        paymentMethod: paymentMethod,
        ticketProcessType: ticketProcessType?.[0],
        paymentAdapterIdList: paymentAdapterIdList,
      }
    }

    if (filters.withoutPaymentMethod) {
      query.paymentMethods = []
    }

    let response = await reportTicketsAPI(query)

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.data) {
      setBlocks({
        [new Date().getTime()]: response.data.data.sort((block1, block2) => {
          return orderStatuses.indexOf(block1.status) - orderStatuses.indexOf(block2.status)
        }),
      })
    }
  }

  useEffect(() => {
    updateReportBlocks()
  }, [])

  const blocksRender = Object.values(blocks)[0].map((statusData, index) => (
    <div className='col-xl-4' key={index}>
      <ReportBlock
        className='card-xl-stretch mb-xl-8'
        color={STATUS_COLOR[statusData.status]}
        status={statusData.status}
        sum={formatSum(statusData.sum)}
        count={statusData.ticketsCount}
        key={index}
      />
    </div>
  ))

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Отчёт</PageTitle>
        <div className='card-body border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Отчёт</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <ReportTicketsFilter
            initialFilter={filters}
            updateFilters={setFilters}
            callback={(reset) => updateReportBlocks(reset)}
            type='payout'
          />
        </div>
      </div>

      <div className='card'>
        <div className='row g-5 g-xl-8'>{blocksRender}</div>
      </div>
    </div>
  )
}

export default ReportTickets
