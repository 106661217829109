import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import ListRequisites from './routes/ListRequisites/ListRequisites'
import { EditRequisite } from './routes/EditRequisite/EditRequisite'
import CashboxesPriorities from './routes/CashboxesPriorities'
import PaymentAdapters from './routes/PaymentAdapters/PaymentAdapters'
import PaymentAdaptersPriorities from './routes/PaymentAdapterPriorities/PaymentAdapterPriorities'
import { PrivateRoute } from '../auth/PrivateRoute'
import { ROLE } from '../auth'
import ResuisiteClients from './routes/ResuisiteClients/ResuisiteClients'

const SettingsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='/requisites'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <ListRequisites key={'reservationDisabledFalse'} reservationDisabled={false} />
            </PrivateRoute>
          }
        />
        <Route
          path='/disabledrequisites'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <ListRequisites key={'reservationDisabledTrue'} reservationDisabled={true} />
            </PrivateRoute>
          }
        />
        <Route
          path='/requisite-edit/:id'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <EditRequisite />
            </PrivateRoute>
          }
        />
        <Route
          path='/requisite-edit/'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <Navigate to='/error/404' />
            </PrivateRoute>
          }
        />
        <Route
          path='/requisite-add'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <EditRequisite />
            </PrivateRoute>
          }
        />

        <Route
          path='/requisite-clients'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <Navigate to='/error/404' />
            </PrivateRoute>
          }
        />

        <Route
          path='/requisite-clients/:id'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_CASHIER,
              ]}
            >
              <ResuisiteClients />
            </PrivateRoute>
          }
        />

        <Route index element={<Navigate to='/requisites' />} />
      </Route>
      <Route
        path='/cashboxes-priorities'
        element={
          <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
            <CashboxesPriorities />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to='/cashboxes-priorities' />} />
      </Route>
      <Route
        path='/payment-adapters'
        element={
          <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
            <PaymentAdapters />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to='/payment-adapters' />} />
      </Route>
      <Route
        path='/payment-adapter-priorities'
        element={
          <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR]}>
            <PaymentAdaptersPriorities />
          </PrivateRoute>
        }
      >
        <Route index element={<Navigate to='/payment-adapters-priorities' />} />
      </Route>
    </Routes>
  )
}

export default SettingsPage
