import { formatSum } from '../../../../utils'
import { PaymentTicket, TicketListObject } from '../core/_models'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { convertUTCtoMSK } from '../../../../utils'
import { KTIcon } from '../../../../_metronic/helpers'
import { internalCancelTicketAPI, ticketFileGet } from '../core/_requests'
import { openFile } from '../../../../utils/openFile'
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { SelectStyles } from '../../../../components/Select/Select.theme'
import { useThemeMode } from '../../../../_metronic/partials'

const statusOptions = [
  {
    value: 'COMPLETED',
    label: 'Завершен',
  },
  {
    value: 'EXPIRED',
    label: 'Просрочен',
  },
]

const filterStatus = (status: string) => {
  if (status === 'EXPIRED') {
    return [
      {
        value: 'COMPLETED',
        label: 'Завершен',
      },
    ]
  } else if (status === 'COMPLETED') {
    return [
      {
        value: 'EXPIRED',
        label: 'Просрочен',
      },
    ]
  } else {
    return []
  }
}

const getStatusValue = (status: string) => {
  return statusOptions.filter((el) => el.value === status)
}

const FinishedTicketItem = (props: {
  ticket: PaymentTicket
  setShowAlert: Dispatch<SetStateAction<boolean>>
  targetTicket?: PaymentTicket | null
  setStatusModal: Dispatch<SetStateAction<boolean>>
  setTargetTicket: Dispatch<SetStateAction<PaymentTicket | null>>
  setTickets: Dispatch<SetStateAction<TicketListObject>>
}) => {
  const ticket = props.ticket
  const targetTicket = props.targetTicket
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const { mode: theme } = useThemeMode()
  const [options, setOptions] = useState(statusOptions)
  const [selectValue, setSelectValue] = useState(getStatusValue(ticket.status))

  const { currentUser } = useAuth()

  useEffect(() => {
    setSelectValue(getStatusValue(ticket.status))
    setOptions(filterStatus(ticket?.status))
  }, [targetTicket, ticket.status])

  const onFileClick = async () => {
    const res = await ticketFileGet(ticket.bill)
    openFile(res.data)
  }

  const handleCopyToClipboard = (e: MouseEvent<HTMLSpanElement>) => {
    const span = e.target as HTMLElement
    navigator.clipboard.writeText(span.innerText)
    props.setShowAlert(true)
    setTimeout(() => {
      props.setShowAlert(false)
    }, 1400)
  }

  const changeStatusHandler = async (
    status: SingleValue<{
      value: string
      label: string
    }>
  ) => {
    if (status?.value === 'COMPLETED') {
      props.setTargetTicket(ticket)
      props.setTickets((prev: any) => {
        return {
          ...prev,
          [ticket.token]: { ...ticket, status: 'COMPLETED' },
        }
      })
      props.setStatusModal(true)
      setSelectValue([statusOptions[0]])
    } else if (status?.value === 'EXPIRED') {
      const response = await internalCancelTicketAPI(ticket.token)
      setSelectValue([statusOptions[1]])
      props.setTickets((prev: any) => ({ ...prev, [ticket.token]: response?.data.ticket }))
    }
  }

  return (
    <tr>
      <td>
        <p className='mb-4'>{ticket.token}</p>
        <div className='d-flex-column'>
          <p className='fw-bold fs-9'>Мерчант ID:</p>
          <span className='fs-9'>{ticket?.transactionId}</span>
        </div>
      </td>
      <td>{ticket.createdAt ? convertUTCtoMSK(ticket.createdAt) : '-'}</td>
      <td>{ticket.completionDate ? convertUTCtoMSK(ticket.completionDate) : '-'}</td>
      <td>{ticket.amount ? formatSum(ticket.amount) : '-'}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <>
          <td className='max-w-220px position-relative'>
            {ticket?.lastExternalTransaction?.paymentAdapterType === 'EXTERNAL' ? (
              <>
                <span className='badge '>
                  {ticket?.lastExternalTransaction?.paymentAdapterName || '-'}
                </span>
                <span className='separator' />
                {ticket?.lastExternalTransaction?.paymentId ? (
                  <span
                    onMouseEnter={() => setShowTooltip((prev) => !prev)}
                    onMouseLeave={() => setShowTooltip((prev) => !prev)}
                    onClick={handleCopyToClipboard}
                    className='badge d-block text-truncate w-200px cursor-pointer text-start'
                  >
                    {ticket?.lastExternalTransaction?.paymentId || '-'}
                  </span>
                ) : (
                  <span className='badge'>{'-'}</span>
                )}
                {showTooltip && (
                  <div
                    style={{
                      width: 'fit-content',
                      zIndex: 20,
                    }}
                    className='badge position-absolute top-5 left-15px bg-success text-white'
                  >
                    <p>{ticket?.lastExternalTransaction?.paymentId}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <span className='badge'>{ticket.issuedRequisite?.name || '-'}</span>
                <span className='separator' />
                <span className='badge'>{ticket.issuedRequisite?.requisiteType || '-'}</span>
              </>
            )}
          </td>
          <td>{ticket.sms ? ticket.sms.text : 'смс не поступило'}</td>
          <td>{ticket.externalUser?.id}</td>
        </>
      )}
      <td>{ticket.externalUser?.externalId}</td>
      <td>{ticket.merchant?.title}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <>
          <td>{ticket.cashBox ? ticket.cashBox.title : ''}</td>
          <td>{ticket.executor ? ticket.executor.uuid : ''}</td>
          <td>{ticket.source.name}</td>
        </>
      )}
      <td>{ticket?.lastExternalTransaction?.paymentId || '-'}</td>
      <td className='text-center'>
        {ticket.bill ? (
          <span onClick={onFileClick} className='cursor-pointer'>
            <KTIcon className='fs-1' iconName='file' />
          </span>
        ) : (
          ''
        )}
      </td>
      {IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
        currentUser
      ) ? (
        <td className='w-150px'>
          {ticket.status === 'DECLINED' ? (
            <p className='text-center'>Отменен</p>
          ) : (
            <Select
              styles={SelectStyles(theme)}
              onChange={changeStatusHandler}
              options={options}
              hideSelectedOptions={true}
              value={selectValue}
              defaultValue={getStatusValue(ticket.status)}
            />
          )}
        </td>
      ) : (
        <></>
      )}
    </tr>
  )
}

export default FinishedTicketItem
