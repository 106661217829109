import axios from 'axios'
import {
  ParamsTicketsRequest,
  ReportTicketsResponse,
  TicketActionResponse,
  TicketListResponse,
} from './_models'
import { ROLE, UserModel } from '../../auth'
import { getUserRolesByHierarchy } from '../../auth/PrivateRoute'
import { API_URL } from '../../../../constants'
import Swal from 'sweetalert2'

export const GET_LIST_TICKETS = `${API_URL}/api/payment/list`
export const GET_EMPLOYEE_LIST_TICKETS = `${API_URL}/api/internal/payment/list`
export const INIT_TICKET = `${API_URL}/api/internal/payment/init`
export const TICKET_APPEND_MERCHANT = `${API_URL}/api/internal/payment/append-merchant/`
export const UPLOAD_TICKET_FILE = `${API_URL}/api/payment/bill/`
export const FINISH_TOKEN = `${API_URL}/api/payment/complete/__token__`
export const FINISH_EMPLOYEE_TOKEN = `${API_URL}/api/internal/payment/complete/__token__`
export const CANCEL_TOKEN = `${API_URL}/api/payment/decline/__token__`
export const CANCEL_EMPLOYEE_TOKEN = `${API_URL}/api/internal/payment/decline/__token__`
export const CONFIRM_TICKET_BY_TOKEN = `${API_URL}/api/internal/payment/confirm/`
export const REPORT_TICKETS = `${API_URL}/api/reports/tickets/__type__/summary`
export const REPORT_EXPORT = `${API_URL}/api/reports/tickets/__type__/full`

export function getListTickets(params: Partial<ParamsTicketsRequest>, currentUser?: UserModel) {
  if (!params.perPage) {
    params.perPage = 99
  }

  if (!params.page) {
    params.page = 1
  }

  const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)
  const url = allRolesCurrentUser.includes(ROLE.ROLE_EMPLOYEE)
    ? GET_EMPLOYEE_LIST_TICKETS
    : GET_LIST_TICKETS

  return axios.get<TicketListResponse>(url, {
    params: params,
  })
}

export function initTicketAPI(params: {
  requisiteId?: string
  merchantToken: string
  amount: string
}) {
  return axios.post(INIT_TICKET, { ...params, amount: parseFloat(params.amount) })
}

export function ticketAppendMerchantGet(token: string, merchantToken: string) {
  return axios.get(`${TICKET_APPEND_MERCHANT}${token}`, {
    params: {
      merchantToken,
    },
  })
}

export function ticketFileGet(url: string) {
  return axios.get(url, { responseType: 'blob' })
}

export function uploadTicketFilePost(formData: FormData, token: string) {
  return axios.post(`${UPLOAD_TICKET_FILE}${token}`, formData)
}

export function finishTicketAPI(
  token: string,
  externalUser: string,
  amount?: string,
  currentUser?: UserModel
) {
  const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)
  const url = allRolesCurrentUser.includes(ROLE.ROLE_EMPLOYEE)
    ? FINISH_EMPLOYEE_TOKEN
    : FINISH_TOKEN

  return axios.get<TicketActionResponse>(url.replace('__token__', token), {
    params: {
      ...(externalUser ? { externalUserId: externalUser } : {}),
      ...(amount ? { amount } : {}),
    },
  })
}

export async function internalFinishTicketAPI(
  token: string,
  externalUser: string,
  amount?: string
) {
  const url = FINISH_EMPLOYEE_TOKEN
  const response = await axios.get<TicketActionResponse>(url.replace('__token__', token), {
    params: {
      ...(externalUser ? { externalUserId: externalUser } : {}),
      ...(amount ? { amount } : {}),
    },
  })

  if (response.status !== 200) {
    Swal.fire('Ошибка', 'Этот тикет нельзя изменить', 'error')
  } else {
    return response
  }
}

export function cancelTicketAPI(token: string, currentUser?: UserModel) {
  const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)
  const url = allRolesCurrentUser.includes(ROLE.ROLE_EMPLOYEE)
    ? CANCEL_EMPLOYEE_TOKEN
    : CANCEL_TOKEN

  return axios.get<TicketActionResponse>(url.replace('__token__', token))
}

export async function internalCancelTicketAPI(token: string) {
  const url = CANCEL_EMPLOYEE_TOKEN
  const response = await axios.get<TicketActionResponse>(url.replace('__token__', token))
  if (response.status === 422 || response.status === 503) {
    Swal.fire('Ошибка', 'Этот тикет нельзя изменить', 'error')
  } else {
    return response
  }
}

export function confirmTicketAPI(token: string) {
  return axios.get<TicketActionResponse>(`${CONFIRM_TICKET_BY_TOKEN}${token}`)
}

export function reportTicketsAPI(filterData: any, exportXslx?: boolean) {
  const url = exportXslx ? REPORT_EXPORT : REPORT_TICKETS
  const copyObject = { ...filterData }

  delete copyObject.ticketType

  return axios.post<Partial<ReportTicketsResponse>>(
    url.replace('__type__', filterData.ticketType + ''),
    copyObject,
    exportXslx ? { responseType: 'blob' } : {}
  )
}
