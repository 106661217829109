import DateTimeRangeFilter, { DateFilterObject } from '../../../common/filter/DateTimeRangeFilter'
import { SelectData } from '../../../common/filter/models'
import CashBoxFilter, {
  selectType as cashBoxSelectType,
} from '../../../common/filter/CashBoxFilter'
import MerchantFilter, {
  selectType as merchantSelectType,
} from '../../../common/filter/MerchantFilter'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import PaymentAdapterFilter from '../../../common/filter/PaymentAdapterFilter'
import PaymentMethodsFilter from '../../../common/filter/PaymentMethodsFilter'

const ReportTicketsFilter = ({
  initialFilter,
  updateFilters,
  callback,
  type,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (reset?: boolean) => void
  type: 'payout' | 'payment'
}) => {
  const { currentUser } = useAuth()
  const updateTime = (filterData: DateFilterObject) => {
    updateFilters({
      ...initialFilter,
      dateStart: filterData.dateStart,
      dateEnd: filterData.dateEnd,
    })
  }

  const onChangeSelect = (selectData: SelectData) => {
    const filterObject = { ...initialFilter }

    switch (selectData.type) {
      case cashBoxSelectType:
        if (!selectData.value.length) {
          filterObject.cashBoxIdList = []
        } else {
          filterObject.cashBoxIdList = selectData.value
        }

        break
      case merchantSelectType:
        if (!selectData.value.length) {
          filterObject.merchantTokenList = []
        } else {
          filterObject.merchantTokenList = selectData.value
        }

        break
      case 'paymentAdapter':
        if (!selectData.value.length) {
          filterObject.paymentAdapterIdList = []
        } else {
          filterObject.paymentAdapterIdList = selectData.value
        }
        break

      case 'paymentMethodType':
        if (!selectData.value.length) {
          filterObject.paymentMethods = []
        } else {
          filterObject.paymentMethods = selectData.value
        }
        break
    }

    updateFilters(filterObject)
  }

  return (
    <div className='row'>
      <div className='col-lg-8'>
        <DateTimeRangeFilter filter={initialFilter} updateTime={updateTime} />
      </div>
      <div className='d-flex col-lg-4 gap-4 align-items-center'>
        <button className='btn btn-success' onClick={() => callback(false)}>
          Фильтровать
        </button>

        <button className='btn btn-primary' onClick={() => callback(true)}>
          Сбросить фильтр
        </button>
      </div>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) ? (
        <>
          <div className='col-lg-4 mt-10'>
            <CashBoxFilter
              changeSelect={onChangeSelect}
              isMultiple={true}
              value={initialFilter.cashBoxIdList}
            />
          </div>
          <div className='col-lg-4 mt-10'>
            <MerchantFilter
              changeSelect={onChangeSelect}
              isMultiple={true}
              value={initialFilter.merchantTokenList}
            />
          </div>
        </>
      ) : null}

      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR], currentUser) ? (
        <>
          <div className='col-lg-4 mt-10'>
            <PaymentAdapterFilter
              isMultiple
              changeSelect={onChangeSelect}
              value={initialFilter.paymentAdapterIdList}
            />
          </div>
        </>
      ) : (
        ''
      )}
      {IsGranted(
        [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_SENIOR_OPERATOR],
        currentUser
      ) ? (
        <>
          <div className='col-lg-4 mt-10'>
            <PaymentMethodsFilter
              isMultiple
              changeSelect={onChangeSelect}
              value={initialFilter.paymentMethods}
            />
          </div>
          {type === 'payment' && (
            <div className='col-lg-4 mt-10 d-flex align-items-center'>
              <label className='form-check-label me-3' htmlFor='flexCheckDefault'>
                Без платежного метода
              </label>
              <input
                className='form-check-input '
                type='checkbox'
                onChange={(e) => {
                  updateFilters((prev: any) => ({
                    ...prev,
                    withoutPaymentMethod: e.target.checked,
                  }))
                }}
                checked={initialFilter.withoutPaymentMethod}
                id='flexCheckDefault'
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ReportTicketsFilter
