import * as Yup from 'yup'
import { ROLE } from '../../app/modules/auth'
import { INewTicket } from './NewTicket.types'

export const NEW_TICKET_SCHEMA = Yup.object().shape({
  amount: Yup.string().required('Обязательное поле'),
  requisiteId: Yup.string().required('Обязательное поле'),
  merchantToken: Yup.string().required('Обязательное поле'),
})

export const NEW_OPERATOR_TICKET_SCHEMA = Yup.object().shape({
  amount: Yup.string().required('Обязательное поле'),
  merchantToken: Yup.string().required('Обязательное поле'),
  paymentAdapterId: Yup.string().required('Обязательное поле'),
})

export const DEFAULT_NEW_TICKET_OBJ: INewTicket = {
  amount: '',
  requisiteId: '',
  merchantToken: '',
}

export const ROLES_FOR_NEW_TICKET = [
  ROLE.ROLE_ADMIN,
  ROLE.ROLE_CASHIER,
  ROLE.ROLE_SENIOR_CASHIER,
  ROLE.ROLE_MANAGER,
]
