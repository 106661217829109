import axios from 'axios'
import { UserModel } from '../../auth'
import { GET_LIST_TICKETS, GET_TICKET } from './consts'
import {
  IParamsTicketsListRequest,
  ITicketResponse,
  ITicketsListResponse,
} from './ticketsList.types'

export const getTicketsList = (
  params: Partial<IParamsTicketsListRequest>,
  currentUser?: UserModel
) => {
  if (!params.perPage) {
    params.perPage = 99
  }

  if (!params.page) {
    params.page = 1
  }

  const url = GET_LIST_TICKETS

  return axios.get<ITicketsListResponse>(url, {
    params: params,
  })
}

export const getTicket = (id: string) => {
  const url = GET_TICKET

  return axios.get<ITicketResponse>(`${url}/${id}`)
}
